import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "documentation"
    }}>{`Documentation`}</h1>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Ticket Distribution creates digital tickets and places them in NOD (National Order Database) for pickup by apps, as well as creating PDF tickets both for download and for emailing to end-users.`}</p>
    <h2 {...{
      "id": "domain-types"
    }}>{`Domain types`}</h2>
    <ul>
      <li parentName="ul">{`TicketDistribution: Data that forms the basis for a ticket which is sent to NOD and consequently picked up by a client.`}</li>
      <li parentName="ul">{`TicketDistributionGroup: Group of tickets. This always corresponds to an orderLine - and a PDF ticket (if created).`}
        <ul parentName="li">
          <li parentName="ul">{`There can be more than one TicketDistribution in each group, and there can be more than one TicketDistributionGroup connected to an order.`}</li>
          <li parentName="ul">{`For example, an OrderLine with more than one leg might have more than one TicketDistribution, but only one TicketDistributionGroup.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`PdfTicket: Printable PDF of a ticket, represents at least one TicketDistribution, and always one TicketDistributionGroup.`}
        <ul parentName="li">
          <li parentName="ul">{`For example, that same OrderLine with more than one leg will have just one PDF, but that PDF might have several different TicketDistributions connected to it, so there could be several sheets with individual QR codes.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "workflow"
    }}>{`Workflow`}</h2>
    <ol>
      <li parentName="ol">{`After payment is registered for an order, and the order receives the status CONFIRMED, Ticket Distribution automatically creates TicketDistributions and TicketDistributionGroups.`}
        <ul parentName="li">
          <li parentName="ul">{`There is a `}<inlineCode parentName="li">{`/{orderId}/generate`}</inlineCode>{` endpoint, but a client should only use this endpoint if there is no TicketDistributionGroup available on the search endpoint even after a long delay.`}</li>
          <li parentName="ul">{`It is not possible to generate TicketDistributions for an order that is not CONFIRMED. If an order is paid, but still not CONFIRMED after a long delay, this is possibly a bug in the Order API and has nothing to do with Ticket Distribution.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`If a client wants to know when a TicketDistributionGroup is created for an order, use the search endpoint with the orderId and orderVersion parameters. This might take a few seconds at times.`}</li>
      <li parentName="ol">{`If a PDF ticket is requested by the user, the client should send a request to the `}<inlineCode parentName="li">{`/{orderId}/createOrGet`}</inlineCode>{` endpoint.`}
        <ul parentName="li">
          <li parentName="ul">{`This does not require the TicketDistributionGroup to be manually created before calling the endpoint. The endpoint will, in fact, wait for the TicketDistributionGroup to be automatically created before generating the PDF.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`If the end-user requested the PDF tickets to be sent via email, use the `}<inlineCode parentName="li">{`/{orderId}/send`}</inlineCode>{` endpoint to send the PDFs to the specified email address.`}
        <ul parentName="li">
          <li parentName="ul">{`The client needs the TicketDistributionIds from the search endpoints for this request, so it has to wait until the TicketDistributionGroups are generated.`}</li>
          <li parentName="ul">{`It is not necessary to use the `}<inlineCode parentName="li">{`/{orderId}/createOrGet`}</inlineCode>{` endpoint first. These two endpoints are for different workflows - one is for downloading the PDF, the other is for sending.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`If an Order is later credited or changed so that the tickets are no longer valid, the TicketDistributions will be cancelled, and new ones created if necessary.`}
        <ul parentName="li">
          <li parentName="ul">{`Note that PDF tickets still exist - though they will no longer give the end-user a right to travel.`}</li>
          <li parentName="ul">{`The client is responsible for verifying that a PDF ticket has not been changed or cancelled. This can be done by checking the Ticket Distribution status (see below).`}</li>
        </ul>
      </li>
    </ol>
    <h2 {...{
      "id": "checking-ticketdistribution-status"
    }}>{`Checking TicketDistribution status`}</h2>
    <p>{`NOD is limited in its ability to show the validity of a ticket due to architectural constraints.
Therefore, it is highly recommended to check validity through this service as well as NOD.
The simplest way to do this is to check the `}<inlineCode parentName="p">{`ticket-distributions/{ticketDistributionId}/status`}</inlineCode>{` endpoint,
but if you don't have the ticketDistributionId you can use the `}<inlineCode parentName="p">{`ticket-distribution-groups`}</inlineCode>{` endpoint to search instead.`}</p>
    <h2 {...{
      "id": "ticketdistribution-as-a-kafka-producer"
    }}>{`TicketDistribution as a Kafka-producer`}</h2>
    <p>{`TicketDistribution produce TicketDistributionGroupEvents. The Avro-schema for TicketDistributionEvents can be found in the `}<a parentName="p" {...{
        "href": "https://bitbucket.org/enturas/ticket-distribution/src/master/src/main/avro/TicketDistributionGroupEvent.avsc"
      }}>{`ticket-distribution-repo`}</a>{`: src/main/avro/TicketDistributionGroupEvent.avsc`}</p>
    <h3 {...{
      "id": "event-types"
    }}>{`Event-types`}</h3>
    <h4 {...{
      "id": "ticketdistributiongroupevents"
    }}>{`TicketDistributionGroupEvents`}</h4>
    <p>{`The following types of TicketDistributionGroupEvents are produced and emitted by TicketDistribution:`}</p>
    <ul>
      <li parentName="ul">{`TicketDistributionActivated`}</li>
      <li parentName="ul">{`TicketDistributionAdded`}</li>
      <li parentName="ul">{`TicketDistributionCancelled`}</li>
      <li parentName="ul">{`TicketDistributionCancelledExternally`}</li>
      <li parentName="ul">{`TicketDistributionDistributed`}</li>
      <li parentName="ul">{`TicketDistributionExpired`}</li>
      <li parentName="ul">{`TicketDistributionFailed`}</li>
      <li parentName="ul">{`TicketDistributionGroupCreated`}</li>
      <li parentName="ul">{`TicketDistributionPickedUp`}</li>
    </ul>
    <h4 {...{
      "id": "allticketdistributiongroupsfororderversioncompletedevents"
    }}>{`AllTicketDistributionGroupsForOrderVersionCompletedEvents`}</h4>
    <p>{`The following types of AllTicketDistributionGroupsForOrderVersionCompletedEvents are produced and emitted by TicketDistribution.
Note that these are only emitted once all TicketDistributionGroups for an orderVersion are processed.`}</p>
    <ul>
      <li parentName="ul">{`AllTicketDistributionGroupsForOrderVersionGenerated`}</li>
      <li parentName="ul">{`AllTicketDistributionGroupsForOrderVersionDistributed (not yet implemented)`}</li>
    </ul>
    <h3 {...{
      "id": "queues"
    }}>{`Queues`}</h3>
    <h4 {...{
      "id": "ticketdistributiongroupevents-1"
    }}>{`TicketDistributionGroupEvents`}</h4>
    <p>{`There is one queue per environment, and the queues are named as following:`}</p>
    <ul>
      <li parentName="ul">{`ticket-distribution-group-events-dev`}</li>
      <li parentName="ul">{`ticket-distribution-group-events-staging`}</li>
      <li parentName="ul">{`ticket-distribution-group-events-productions`}</li>
    </ul>
    <h4 {...{
      "id": "allticketdistributiongroupsfororderversioncompletedevents-1"
    }}>{`AllTicketDistributionGroupsForOrderVersionCompletedEvents`}</h4>
    <p>{`There is one queue per environment, and the queues are named as following:`}</p>
    <ul>
      <li parentName="ul">{`all-ticket-distribution-groups-for-order-version-completed-dev`}</li>
      <li parentName="ul">{`all-ticket-distribution-groups-for-order-version-completed-staging`}</li>
      <li parentName="ul">{`all-ticket-distribution-groups-for-order-version-completed-productions`}</li>
    </ul>
    <h1 {...{
      "id": "ticket-distribution-api-reference"
    }}>{`Ticket Distribution API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "http://petstore.swagger.io/?url=https://api.entur.io/api-docs/ticket-distribution?group%3dpublic"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <Swagger url="https://api.entur.io/api-docs/ticket-distribution?group=public" baseUrl="https://api.entur.io/sales" mdxType="Swagger" />
l

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      